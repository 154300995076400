const MM_ERR_WITH_INFO_START =
  "VM Exception while processing transaction: revert ";
const INSUFFICENT_FUNDS = "insufficient funds for gas * price + value";

interface ErrorWithMessage {
  message: string;
}

interface ErrorWithData {
  data: {
    message: string;
  };
}

interface ErrorWithResponse {
  response: {
    data: {
      error: string;
    };
  };
}

const parseError = (e: ErrorWithMessage | ErrorWithData | ErrorWithResponse | null | undefined | any): string => {
  if (!e) {
    return "An unknown error occurred";
  }

  if ('data' in e && e.data && 'message' in e.data) {
    if (e.data.message.startsWith(MM_ERR_WITH_INFO_START)) {
      return e.data.message.replace(MM_ERR_WITH_INFO_START, "");
    } else if (e.data.message.includes(INSUFFICENT_FUNDS)) {
      return "Not enough balance for gas fees. Please add more funds to your wallet and try again.";
    }
  }

  if ('response' in e && e.response && 'data' in e.response && e.response.data && 'error' in e.response.data) {
    return e.response.data.error;
  }

  if ('message' in e) {
    if (e.message.includes(INSUFFICENT_FUNDS)) {
      return "Not enough balance for gas fees. Please add more funds to your wallet and try again.";
    }
  }

  return "An unknown error occurred";
}

export default parseError;