import {
  Box,
  Button,
  Container,
  Drawer,
  makeStyles,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useCallback, useState } from "react";
import { useLocation } from "react-router";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Attest from "./components/Attest";
import Footer from "./components/Footer";
import HeaderText from "./components/HeaderText";
import NFT from "./components/NFT";
import NFTOriginVerifier from "./components/NFTOriginVerifier";
import Recovery from "./components/Recovery";
import TokenOriginVerifier from "./components/TokenOriginVerifier";
import Transfer from "./components/Transfer";
import UnwrapNative from "./components/UnwrapNative";
import USDC from "./components/USDC";
import WithdrawTokensTerra from "./components/WithdrawTokensTerra";
import { CLUSTER } from "./utils/consts";
import ImageLogo from "./images/Logo.svg";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "hsla(0 0% 0% / 0.5)",
    padding: "0.5rem 0",
    marginBottom: "1rem",
    "& img": {
      height: "3.75rem",
      margin: "0 auto",
    },
    "& button": {
      color: "rgb(248 149 45)",
      background: "transparent",
      border: "none",
      cursor: "pointer",
      transition: "all 0.1s ease-in-out",
      "&:hover": {
        color: "#fff",
      },
    },
    "& svg": {
      width: "2rem",
      height: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  spacer: {
    flex: 1,
    width: "100vw",
  },
  link: {
    ...theme.typography.body2,
    fontWeight: 600,
    marginLeft: theme.spacing(4),
    textUnderlineOffset: "6px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2.5),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1),
    },
    "&.active": {
      textDecoration: "underline",
    },
  },
  bg: {
    position: "relative",
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    backgroundImage:
      "linear-gradient(to right bottom,rgba(255,193,132,.25),rgba(0,0,0,.9)),url(/bg.webp)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  brandLink: {
    display: "inline-flex",
    alignItems: "center",
    "&:hover": {
      textDecoration: "none",
    },
  },
  btn: {
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
    letterSpacing: ".05em",
    padding: "1rem 0",
    borderRadius: "0.5rem",
    width: "100%",
    transitionDuration: ".5s",
    boxShadow: "0px 5px 25px -5px rgba(81,44,7,.5)",

    "&:hover": {
      color: "rgb(248 149 45)",
      transition: "all",
      transitionDuration: ".5s",
    },
  },
  sideBar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "30px 24px",
    height: "100vh",
    position: "sticky",
    top: 0,
    width: "max(15vw, 250px)",
    borderRight: "7px solid rgb(248 149 45)",
    background: theme.palette.main,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  sideBarContainer: {
    height: "150px",
    fontSize: "24px",
    fontWeight: "bold",
  },
  sideBarContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    margin: "3rem 0",
  },
  contentContainer: {
    width: "100%",
    // paddingTop: "24px",
    padding: "0",
    overflowY: "auto",
    // scrollBarWidth
    "&::-webkit-scrollbar": {
      width: "0rem",
    },
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "30px 24px",
    height: "100vh",
    position: "sticky",
    top: 0,
    width: "max(15vw, 250px)",
    borderRight: "7px solid rgb(248 149 45)",
  },
}));

function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const classes = useStyles();
  const { pathname } = useLocation();
  const handleClusterChange = useCallback((event) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("cluster", event.target.value);
    window.location.search = urlParams;
  }, []);
  const handleClose = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);
  const handleOpen = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const SidebarContent = () => (
    <>
      <div className={classes.sideBarContainer}>
        <img src={ImageLogo} alt="logo" style={{cursor: "pointer"}} onClick={() => window.open("/", "_self")} />
        <div className={classes.sideBarContent}>
          <Button
            className={classes.btn}
            component={Link}
            to="https://www.carbify.io/"
            onClick={() => {
              handleClose()
              window.open("https://www.carbify.io/", "_blank")
            }}
          >
            Carbify
          </Button>
          <Button
            className={classes.btn}
            component={Link}
            to="https://dashboard.carbify.io/"
            onClick={() => {
              handleClose()
              window.open("https://dashboard.carbify.io/", "_blank")
            }}
          >
            Dashboard
          </Button>
          <Button
            className={classes.btn}
            component={Link}
            to="https://docs.carbify.io/"
            onClick={() => {
              handleClose()
              window.open("https://docs.carbify.io/", "_blank")
            }}
          >
            Docs
          </Button>
        </div>
      </div>

      {/*<Select
        value={CLUSTER}
        onChange={handleClusterChange}
        variant="outlined"
        margin="dense"
      >
        <MenuItem value="testnet">Testnet</MenuItem>
        <MenuItem value="devnet">Devnet</MenuItem>
      </Select>
  */}
    </>
  );
  return (
    <div className={classes.bg}>
      <div>
        <div className={classes.sideBar}>
          <SidebarContent />
        </div>
      </div>
      <Box className={classes.contentContainer}>
        <div className={classes.appBar}>
          <Toolbar>
            <button onClick={handleOpen}>
              <MenuIcon />
            </button>
            <img src={ImageLogo} alt="logo" />
          </Toolbar>
        </div>
        {["/transfer", "/nft", "/redeem"].includes(pathname) ? (
          <Container maxWidth="md">
            <HeaderText
              white
            >
              Carbibridge
            </HeaderText>
          </Container>
        ) : null}
        <Switch>
          <Route exact path="/usdc">
            <USDC />
          </Route>
          <Route exact path="/transfer">
            <Transfer />
          </Route>
          <Route exact path="/nft">
            <NFT />
          </Route>
          <Route exact path="/redeem">
            <Recovery />
          </Route>
          <Route exact path="/nft-origin-verifier">
            <NFTOriginVerifier />
          </Route>
          <Route exact path="/token-origin-verifier">
            <TokenOriginVerifier />
          </Route>
          <Route exact path="/register">
            <Attest />
          </Route>
          <Route exact path="/withdraw-tokens-terra">
            <WithdrawTokensTerra />
          </Route>
          <Route exact path="/unwrap-native">
            <UnwrapNative />
          </Route>
          <Route>
            <Redirect to="/transfer" />
          </Route>
        </Switch>
        <Footer />
      </Box>
      <Drawer open={isDrawerOpen} onClose={handleClose} anchor="left">
        <div className={classes.drawerContainer}>
          <SidebarContent />
        </div>
      </Drawer>
    </div>
  );
}

export default App;
