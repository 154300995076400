import {
  CHAIN_ID_ETH,
  CHAIN_ID_POLYGON,
  CHAIN_ID_KLAYTN,
  CHAIN_ID_SEI,
  ChainId,
  cosmos,
  getEmitterAddressEth,
  isEVMChain,
  parseSequenceFromLogEth,
  transferFromEth,
  transferFromEthNative,
  uint8ArrayToHex,
  CHAIN_ID_TO_NAME,
} from "@certusone/wormhole-sdk";
import { Alert } from "@material-ui/lab";
import { Signer, ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEthereumProvider } from "../contexts/EthereumProviderContext";
import {
  selectTransferAmount,
  selectTransferIsSendComplete,
  selectTransferIsSending,
  selectTransferIsTargetComplete,
  selectTransferOriginChain,
  selectTransferRelayerFee,
  selectTransferSourceAsset,
  selectTransferSourceChain,
  selectTransferSourceParsedTokenAccount,
  selectTransferTargetChain,
} from "../store/selectors";
import {
  setIsSending,
  setIsDelivering,
  setIsVAAPending,
  setSignedVAAHex,
  setTransferTx,
} from "../store/transferSlice";
import {
  ABI_HELLO_WORMHOLE,
  CUSTOMISED_CONTRACTS,
  SEI_TRANSLATER_TARGET,
  getBridgeAddressForChain,
  getTokenBridgeAddressForChain,
} from "../utils/consts";
import { getSignedVAAWithRetry } from "../utils/getSignedVAAWithRetry";
import parseError from "../utils/parseError";
import useTransferTargetAddressHex from "./useTransferTargetAddress";
import { getDeliveryHash } from "../utils/ethereum";
import { sleep } from "../utils/sleep";

type AdditionalPayloadOverride = {
  receivingContract: Uint8Array;
  payload: Uint8Array;
};

function maybeAdditionalPayload(
  recipientChain: ChainId,
  recipientAddress: Uint8Array,
  originChain?: ChainId
): AdditionalPayloadOverride | null {
  if (recipientChain === CHAIN_ID_SEI && originChain !== CHAIN_ID_SEI) {
    return {
      receivingContract: SEI_TRANSLATER_TARGET,
      payload: new Uint8Array(
        Buffer.from(
          JSON.stringify({
            basic_recipient: {
              recipient: Buffer.from(
                // Sei wallet addresses are 20 bytes
                cosmos.humanAddress("sei", recipientAddress.slice(12))
              ).toString("base64"),
            },
          })
        )
      ),
    };
  }
  return null;
}

async function fetchSignedVAA(
  chainId: ChainId,
  emitterAddress: string,
  sequence: string,
  enqueueSnackbar: any,
  dispatch: any
) {
  enqueueSnackbar(null, {
    content: <Alert severity="info">Fetching VAA</Alert>,
  });
  const { vaaBytes, isPending } = await getSignedVAAWithRetry(
    chainId,
    emitterAddress,
    sequence
  );
  if (vaaBytes !== undefined) {
    dispatch(setSignedVAAHex(uint8ArrayToHex(vaaBytes)));
    dispatch(setIsVAAPending(false));
    enqueueSnackbar(null, {
      content: <Alert severity="success">Fetched Signed VAA</Alert>,
    });
  } else if (isPending) {
    dispatch(setIsVAAPending(isPending));
    enqueueSnackbar(null, {
      content: <Alert severity="warning">VAA is Pending</Alert>,
    });
  } else {
    throw new Error("Error retrieving VAA info");
  }
}

function handleError(e: any, enqueueSnackbar: any, dispatch: any) {
  console.error(e);
  enqueueSnackbar(null, {
    content: <Alert severity="error">{parseError(e)}</Alert>,
  });
  dispatch(setIsSending(false));
  dispatch(setIsVAAPending(false));
}

async function evm(
  dispatch: any,
  enqueueSnackbar: any,
  signer: Signer,
  tokenAddress: string,
  decimals: number,
  amount: string,
  recipientChain: ChainId,
  recipientAddress: Uint8Array,
  isNative: boolean,
  chainId: ChainId,
  originChain?: ChainId,
  relayerFee?: string
) {
  dispatch(setIsSending(true));
  try {
    const baseAmountParsed = parseUnits(amount, decimals);
    const feeParsed = parseUnits(relayerFee || "0", decimals);
    const transferAmountParsed = baseAmountParsed.add(feeParsed);
    const additionalPayload = maybeAdditionalPayload(
      recipientChain,
      recipientAddress,
      originChain
    );
    // Klaytn requires specifying gasPrice
    const overrides =
      chainId === CHAIN_ID_KLAYTN
        ? { gasPrice: (await signer.getGasPrice()).toString() }
        : {};
    const receipt = isNative
      ? await transferFromEthNative(
          getTokenBridgeAddressForChain(chainId),
          signer,
          transferAmountParsed,
          recipientChain,
          additionalPayload?.receivingContract || recipientAddress,
          feeParsed,
          overrides,
          additionalPayload?.payload
        )
      : await transferFromEth(
          getTokenBridgeAddressForChain(chainId),
          signer,
          tokenAddress,
          transferAmountParsed,
          recipientChain,
          additionalPayload?.receivingContract || recipientAddress,
          feeParsed,
          overrides,
          additionalPayload?.payload
        );
    dispatch(
      setTransferTx({ id: receipt.transactionHash, block: receipt.blockNumber })
    );
    enqueueSnackbar(null, {
      content: <Alert severity="success">Transaction confirmed</Alert>,
    });
    const sequence = parseSequenceFromLogEth(
      receipt,
      getBridgeAddressForChain(chainId)
    );
    const emitterAddress = getEmitterAddressEth(
      getTokenBridgeAddressForChain(chainId)
    );
    await fetchSignedVAA(
      chainId,
      emitterAddress,
      sequence,
      enqueueSnackbar,
      dispatch
    );
  } catch (e) {
    handleError(e, enqueueSnackbar, dispatch);
  }
}

async function deposit(
  dispatch: any,
  enqueueSnackbar: any,
  sourceChain: Number,
  targetChain: Number,
  signer: any,
  provider: any,
  signerAddress: any,
  amount: string
) {
  dispatch(setIsSending(true));
  try {
    const addrSourceHelloWormhole =
      sourceChain === CHAIN_ID_ETH
        ? CUSTOMISED_CONTRACTS.mainnet.CHAIN_ID_ETH.HELLO_WORMHOLE
        : CUSTOMISED_CONTRACTS.mainnet.CHAIN_ID_POLYGON.HELLO_WORMHOLE;
    const addrTargetHelloWormhole =
      targetChain === CHAIN_ID_ETH
        ? CUSTOMISED_CONTRACTS.mainnet.CHAIN_ID_ETH.HELLO_WORMHOLE
        : CUSTOMISED_CONTRACTS.mainnet.CHAIN_ID_POLYGON.HELLO_WORMHOLE;
    const sourceHelloWormholeContract = new ethers.Contract(
      addrSourceHelloWormhole,
      ABI_HELLO_WORMHOLE,
      signer
    );
    const targetHelloWormholeContract = new ethers.Contract(
      addrTargetHelloWormhole,
      ABI_HELLO_WORMHOLE,
      signer
    );
    const cost =
      await sourceHelloWormholeContract.callStatic.quoteCrossChainCall(
        targetChain,
        200000 
      );
    const tx = await sourceHelloWormholeContract.sendCrossChainDeposit(
      signerAddress,
      ethers.utils.parseEther(amount),
      200000,
      { value: cost }
    );
    dispatch(setTransferTx({ id: tx.hash, block: tx.blockNumber }));
    const rx = await tx.wait();
    enqueueSnackbar(null, {
      content: <Alert severity="success">Transaction confirmed</Alert>,
    });
    dispatch(setSignedVAAHex("null"));
    setIsDelivering(true);
    console.log(CHAIN_ID_TO_NAME[2]);
    const deliveryHash = await getDeliveryHash(
      rx,
      targetChain === CHAIN_ID_ETH ? "ethereum" : "polygon",
      {
        network: "TESTNET",
        provider,
      }
    );
    console.log(deliveryHash);
    /*while (true) {
      await sleep(1000);
      const completed = await targetHelloWormholeContract.seenDeliveryVaaHashes(
        deliveryHash
      );
      if (completed) {
        setIsDelivering(false);
        break;
      }
    }*/
    // console.log(`Reading greeting`);
    // const readGreeting = await targetHelloWormholeContract.latestGreeting();
    // console.log(`Latest greeting: ${readGreeting}`);
  } catch (e) {
    handleError(e, enqueueSnackbar, dispatch);
  }
}

export function useHandleTransfer() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const sourceChain = useSelector(selectTransferSourceChain);
  const sourceAsset = useSelector(selectTransferSourceAsset);
  const originChain = useSelector(selectTransferOriginChain);
  const amount = useSelector(selectTransferAmount);
  const targetChain = useSelector(selectTransferTargetChain);
  const targetAddress = useTransferTargetAddressHex();
  const isTargetComplete = useSelector(selectTransferIsTargetComplete);
  const isSending = useSelector(selectTransferIsSending);
  const isSendComplete = useSelector(selectTransferIsSendComplete);
  const { provider, signer, signerAddress } = useEthereumProvider();
  const sourceParsedTokenAccount = useSelector(
    selectTransferSourceParsedTokenAccount
  );
  const relayerFee = useSelector(selectTransferRelayerFee);
  const decimals = sourceParsedTokenAccount?.decimals;
  const isNative = sourceParsedTokenAccount?.isNativeAsset || false;
  const disabled = !isTargetComplete || isSending || isSendComplete;

  const handleTransferClick = useCallback(() => {
    // TODO: we should separate state for transaction vs fetching vaa
    if (
      isEVMChain(sourceChain) &&
      !!signer &&
      !!sourceAsset &&
      decimals !== undefined &&
      !!targetAddress
    ) {
      deposit(
        dispatch,
        enqueueSnackbar,
        sourceChain,
        targetChain,
        signer,
        provider,
        signerAddress,
        amount
      );
      // evm(
      //   dispatch,
      //   enqueueSnackbar,
      //   signer,
      //   sourceAsset,
      //   decimals,
      //   amount,
      //   targetChain,
      //   targetAddress,
      //   isNative,
      //   sourceChain,
      //   originChain,
      //   relayerFee
      // );
    }
  }, [
    dispatch,
    enqueueSnackbar,
    sourceChain,
    signer,
    relayerFee,
    sourceAsset,
    amount,
    decimals,
    targetChain,
    targetAddress,
    originChain,
    isNative,
  ]);
  return useMemo(
    () => ({
      handleClick: handleTransferClick,
      disabled,
      showLoader: isSending,
    }),
    [handleTransferClick, disabled, isSending]
  );
}
