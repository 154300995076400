import { Button, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    textAlign: "center",
    borderTop: "1px solid #585587",
    position: "relative",
    maxWidth: 1100,
    margin: "80px auto 0px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      // paddingBottom: theme.spacing(12),
    },
  },
  button: {
    textTransform: "none",
    margin: theme.spacing(1),
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Typography variant="body2" gutterBottom>
      </Typography>

      <Button
        variant="outlined"
        href="https://polygonscan.com/token/0xb6a5ae40e79891e4deadad06c8a7ca47396df21c"
        target="_blank"
        rel="noopener noreferrer"
        color="inherit"
        className={classes.button}
      >
        {" "}
        $CBY Polygon
      </Button>

      <Button
        variant="outlined"
        href="https://etherscan.io/token/0xb9d27bc093ed0a3b7c18366266704cfe5e7af77b"
        target="_blank"
        rel="noopener noreferrer"
        color="inherit"
        className={classes.button}
      >
        {" "}
        $CBY Ethereum 
      </Button>
    </footer>
  );
}
